.content-header {
  position: absolute;
  top: 0;
  z-index: 1;
  padding: 30px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  width: 100%;
  @media (max-width: 767px) {
    padding: 0px;
    height: 40px;
    background: #fff;
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
  }
  &-itens {
    display: flex;
    justify-content: space-between;
    &-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 138px;
      height: 40px;
      background-color: $white;
      @media (max-width: 767px) {
        width: initial;
      }
      &:hover,
      &:focus {
        cursor: pointer;
      }
      &-item {
        width: 16px;
        height: 1px;
        background-color: #939191;
        display: block;
        position: relative;
        transform-origin: center;
        transition: 0.5s ease-in-out;
        margin-bottom: 4px;
        &::before {
          content: "";
          display: block;
          background-color: #939191;
          position: absolute;
          width: 100%;
          height: 100%;
          transition: 0.5s ease-in-out;
          top: -4px;
        }
        &::after {
          content: "";
          display: block;
          background-color: #939191;
          position: absolute;
          width: 100%;
          height: 100%;
          transition: 0.5s ease-in-out;
          bottom: -4px;
        }
      }
      p {
        font-size: 10px;
        font-family: "Font Regular";
        color: #4d4d4f;
        margin-bottom: 0px;
        margin-left: 10px;
        text-transform: uppercase;
      }
    }
    &-link {
      display: flex;
      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background-color: $white;
        margin-right: 5px;
        &:hover,
        &:focus {
          cursor: pointer;
        }
        &:last-of-type {
          margin-right: 0px;
          svg {
            margin-right: 3px;
          }
        }
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  &-scrolled {
    position: fixed;
    width: 100%;
    background-color: $white;
    z-index: 900;
    top: -143px;
    transition: 500ms ease-in-out;
    opacity: 0;
    pointer-events: none;
    &-active {
      pointer-events: initial;
      opacity: 1;
      top: 0;
    }
    &-itens {
      width: 100%;
      &-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 90px;
        width: 100%;
        .content-header-itens-menu {
          height: 100%;
        }
        .content-header-itens-link {
          height: 100%;
          &-item {
            height: 100%;
          }
        }
      }
    }
  }
}
