.content-register {
  padding: 70px 0px;
	background: #f8f8f8;
	@media (max-width: 767px){
		padding: 40px 0px;
	}
  &-itens {
    &-header {
      margin-bottom: 45px;
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
      h1 {
        font-size: 35px;
        font-family: "Font Regular";
        color: #4e4e50;
        margin-bottom: 30px;
        text-transform: uppercase;
        @media (max-width: 767px) {
          font-size: 24px;
        }
      }
      p {
        font-size: 18px;
        font-family: "Font Regular";
        color: #4e4e50;
        margin-bottom: 0px;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
    &-form {
      h2 {
        font-size: 35px;
        font-family: "Font Regular";
        color: #4e4e50;
        margin-bottom: 30px;
        text-transform: uppercase;
        @media (max-width: 767px) {
          font-size: 24px;
        }
      }
      &-inputs {
        margin-bottom: 50px;
        position: relative;
        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
        label {
          font-size: 18px;
          font-family: "Font Light";
          color: #4e4e50;
					margin-bottom: 0px;
					@media (max-width: 767px){
						font-size: 14px;
					}
        }
        input,
        select {
          width: 100%;
          height: 48px;
          border: 1px solid #d8d7d3;
          box-shadow: none;
          background-color: $white;
          font-size: 15px;
          font-family: "Font Light";
          color: #4e4e50;
          outline: none;
          padding-left: 15px;
          appearance: none;
          -webkit-appearance: none;
					-moz-appearance: none;
					@media (max-width: 767px){
						height: 40px;
          }
        }
        input:not(:placeholder-shown):not([disabled]) {
          background: initial;
        }
        select {
          background: initial;
        }
        svg {
          position: absolute;
          right: 25px;
          top: 70%;
          transform: translateY(-50%);
        }
      }
      &-div {
				margin-top: 30px;
				@media (max-width: 767px){
					margin-top: 20px;
				}
				&-btn {
					width: 225px;
					height: 48px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 15px;
					font-family: 'Font Light';
					color: $white;
					text-transform: uppercase;
					background: #4E4E50;
					border: 0;
					transition: 500ms;
					&:hover,&:focus {
						cursor: pointer;
						opacity: 0.8;
					}
				}
      }
      .start-field {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 30px;
        input[type="checkbox"] {
          height: 32px;
          width: 32px;
          border: 1px solid #d8d7d3;
          appearance: none;
          margin-right: 10px;
          outline: 0 !important;
					position: relative;
					@media (max-width: 767px){
						height: 24px;
						width: 24px;
					}
          &:checked {
            border: 1px solid #d8d7d3;
          }
          &:checked::before {
            content: "";
            background: url('../icons/check.svg') center no-repeat;
            // color: #4E4E50;
            background-color: $white;
          }
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        label {
          font-size: 18px;
          font-family: "Font Light";
          color: #4e4e50;
          margin-bottom: 0px;
          width: 90%;
					line-height: 1.2;
					@media (max-width: 767px){
						font-size: 14px;
					}
					@media (max-width: 359px){
						font-size: 12px;
					}
          a {
            font-family: "Font Bold";
            text-decoration: underline !important;
            text-decoration-color: #4e4e50;
            &:hover,
            &:focus {
              cursor: pointer;
              text-decoration: underline !important;
							text-decoration-color: #4e4e50;
							opacity: 0.8;
            }
          }
        }
      }
    }
  }
  &-table {
    width: 100%;
    @media (max-width: 991px) {
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    &-header {
      font-size: 12px;
      font-family: "Font Bold";
      color: #4e4e50;
      margin-bottom: 0px;
      border-bottom: 2px solid #4e4e50 !important;
      @media (max-width: 991px) {
        font-size: 8px;
      }
      th {
        &:first-of-type {
          padding-left: 15px;
          @media (max-width: 991px) {
            padding-left: 5px;
          }
        }
        padding: 10px 0px;
      }
    }
    tbody {
      background: $white;
      border: 1px solid #f8f8f8;
      tr {
        border-bottom: 1px solid #dfdfdf;
        td {
          font-size: 14px;
          font-family: "Font Regular";
          color: #302c2d;
          padding: 15px 0px;
          @media (max-width: 991px) {
            font-size: 8px;
          }
          &:first-of-type {
            padding-left: 15px;
            @media (max-width: 991px) {
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}
.content-rgpd {
  padding: 40px 0px 120px 0px;
  background: $white;
  @media (max-width: 767px){
    padding: 30px 0px 60px 0px;
  }
  &-itens {
    &-header {
      margin-bottom: 30px;
      h2 {
        font-size: 35px;
        font-family: 'Font Regular';
        color: #707070;
        margin-bottom: 0px;
        text-transform: uppercase;
        @media (max-width: 767){
          font-size: 4px;
        }
      }
    }
    &-btns {
      display: flex;
      align-items: center;
      @media (max-width: 767px){
        flex-direction: column;
      }
      &-delete {
        width: 225px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-family: 'Font Light';
        color: #4E4E50 !important;
        background: #DDDDDD;
        border: 0px;
        transition: 500ms;
        margin-right: 75px;
        text-transform: uppercase;
        @media (max-width: 767px){
          width: 100%;
          margin-right: 0px;
          margin-bottom: 20px;
        }
        &:hover,&:focus {
          opacity: 0.8;
          cursor: pointer;
        }
      }
      &-export {
        width: 225px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-family: 'Font Light';
        color: #4E4E50 !important;
        background: #DDDDDD;
        border: 0px;
        transition: 500ms;
        text-transform: uppercase;
        @media (max-width: 767px){
          width: 100%;
        }
        &:hover,&:focus {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }
}