.content-banner {
  position: relative;
  &-bg {
    height: 376px;
    width: 100%;
    background-position: top center !important;
    background-attachment: fixed !important;
    @media (min-width: 1900px) {
      background-size: cover !important;
      background-attachment: scroll !important;
    }
  }
  &-image-mobile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.content-home {
  &-itens {
    &-div {
      background: #f2f2f2;
    }
    &-header {
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        justify-content: center;
      }
      &-link {
        font-size: 20px;
        font-family: "Font Regular";
        color: #949494 !important;
        transition: 500ms;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 10px 0px;
        @media (max-width: 991px) {
          font-size: 14px;
          text-align: center;
        }
        @media (max-width: 359px) {
          font-size: 12px;
        }
        // &:first-of-type {
        //   &::after {
        //     content: "";
        //     height: 33px;
        //     width: 1px;
        //     background: #707070;
        //     margin: 0px 30px;
        //     display: flex;
        //     @media (max-width: 767px) {
        //       margin: 0px 15px;
        //     }
        //   }
        // }
        &:hover,
        &:focus {
          cursor: pointer;
          color: #6d6b6b !important;
        }
      }
      .active {
        border-bottom: 2px solid #707070;
      }
      .content-border {
        height: 33px;
        width: 1px;
        background: #707070;
        margin: 0px 30px;
        display: flex;
        @media (max-width: 767px) {
          margin: 0px 15px;
        }
      }
    }
    &-body {
      padding-top: 115px;
      padding-bottom: 120px;
      background: #f8f8f8;
      @media (max-width: 991px) {
        padding-top: 50px;
        padding-bottom: 50px;
      }
      &-elements {
        display: flex;
        align-items: center;
        margin-bottom: 100px;
        @media (max-width: 991px) {
          flex-direction: column;
        }
        &-text {
          width: 40%;
          margin-right: 30px;
          @media (max-width: 991px) {
            width: 100%;
            margin-right: 0px;
            margin-bottom: 30px;
          }
          h2 {
            font-size: 30px;
            font-family: "Font Regular";
            color: #4e4e50;
            margin-bottom: 35px;
            text-transform: uppercase;
            @media (min-width: 992px) and (max-width: 1200px) {
              font-size: 20px;
            }
            @media (max-width: 992px) {
              font-size: 18px;
            }
          }
          p {
            font-size: 18px;
            font-family: "Font Regular";
            color: #4e4e50;
            margin-bottom: 0px;
            @media (max-width: 991px) {
              font-size: 16px;
            }
            @media (min-width: 992px) and (max-width: 1200px) {
              font-size: 16px;
            }
          }
        }
        &-image {
          transition: 500ms;
          cursor: pointer;
          &:hover,
          &:focus {
            transform: scale(1.01);
          }
        }
      }
      &-information {
        @media (max-width: 991px) {
          justify-content: center;
        }
        &-item {
          @media (max-width: 991px) {
            justify-content: center;
            margin-bottom: 30px;
            &:last-of-type {
              margin-bottom: 0px;
            }
          }
          &-text {
            padding: 40px 35px;
            background: $white;
            height: 350px;
            @media (min-width: 992px) and (max-width: 1200px) {
              padding: 30px 20px;
            }
            @media (max-width: 767px) {
              height: initial;
              padding: 30px 20px;
            }
            h2 {
              font-size: 20px;
              font-family: "Font Regular";
              color: #4e4e50;
              margin-bottom: 20px;
              @media (min-width: 992px) and (max-width: 1200px) {
                font-size: 18px;
              }
            }
            p {
              font-size: 15px;
              font-family: "Font Regular";
              color: #4e4e50;
              margin-bottom: 0px;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // display: -webkit-box;
              // -webkit-line-clamp: 10;
              // -webkit-box-orient: vertical;
              @media (min-width: 992px) and (max-width: 1200px) {
                font-size: 14px;
              }
            }
          }
          &-image {
            transition: 500ms;
            position: relative;
            &:hover,
            &:focus {
              cursor: pointer;
              transform: scale(1.02);
            }
          }
        }
      }
    }
  }
}
.content-home-form {
  padding: 100px 0px;
  @media (max-width: 991px) {
    padding: 50px 0px;
  }
  &-itens {
    display: flex;
    justify-content: space-between;
    @media (max-width: 991px) {
      flex-direction: column;
      justify-content: initial;
    }
    &-login {
      width: 40%;
      @media (max-width: 991px) {
        width: 100%;
        margin-bottom: 50px;
      }
      &-header {
        margin-bottom: 20px;
        h2 {
          font-size: 35px;
          font-family: "Font Regular";
          color: #4e4e50;
          margin-bottom: 25px;
          text-transform: uppercase;
          @media (max-width: 991px) {
            font-size: 24px;
            margin-bottom: 20px;
          }
        }
        p {
          font-size: 15px;
          font-family: "Font Light";
          color: #4e4e50;
          margin-bottom: 0px;
          @media (max-width: 991px) {
            font-size: 14px;
          }
        }
      }
      &-inputs {
        input {
          height: 48px;
          width: 100%;
          border: 1px solid #d8d7d3;
          padding-left: 15px;
          font-size: 15px;
          font-family: "Font Light";
          color: #4e4e50;
          margin-bottom: 25px;
          outline: none;
          box-shadow: 0px;
        }
        &-btn {
          width: 170px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #4e4e50;
          font-size: 15px;
          font-family: "Font Light";
          color: $white !important;
          transition: 500ms;
          text-transform: uppercase;
          border: 0;
          &:hover,
          &:focus {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }
    }
    &-register {
      width: 40%;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
  }
}
