body {
  min-width: 320px;
}

// main {
//   margin-top: 130px;
//   @include media-breakpoint-up(lg) {
//     margin-top: 88px;
//     @media (min-width: 992px) and (max-width: 1199px) {
//       margin-top: 85px;
//     }
//   }
// }

p {
  font-size: 14px;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}

.container--semifull {
  @media(min-width: 1900px) {
    max-width: 1535px;
  }
}

.backdrop__bar {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1090;
	background-color: rgba(0,0,0,0.6);
}

.form-search {
  display: flex;

  &__input {
    height: 41px;
    padding: 0 10px;
    border-radius: 0;
    color: $black;
    &:focus {
      box-shadow: none;
    }
  }

  &__button {
    background-color: $red;
    border: 0;
    padding: 0 12px;
  }
}

.btn-whatsapp {
  width: 100%;
  display: block;
  font-size: 22px;
  max-width: 260px;
  color: $white !important;
  @include media-breakpoint-up(lg) {
    font-size: 28px;
    max-width: 350px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 10px;
  }

  &__icon {
    width: 25px;
    margin-right: 10px;
    @include media-breakpoint-up(lg) {
      width: 35px;
      margin-right: 20px;
    }
  }
}

.input-control {
  position: relative;
  margin-bottom: 4px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 5px;
  }
  .input-customized-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.input-control-big {
  margin-bottom: 15px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 30px;
  }
}

.input-customized {
  border-radius: 0;
  color: $gray;
  border: 1px solid $gray;
  padding: .375rem .5rem;
  font-size: $small-14;
  &:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid #FBCD42;
    background:#FDF8E6;
    .input__form__cotacao {
      background: #FBCC44;
    }
  }
}

.input-customized::-webkit-input-placeholder {
  color: $gray;
}
.input-customized::-moz-placeholder {
  color: $gray;
}
.input-customized:-ms-input-placeholder {
  color: $gray;
}
.input-customized:-moz-placeholder {
  color: $gray;
}

select {
  &.input-customized {
    color: $gray;
  }
  &.input-customized--hasvalue {
    color: $gray;
  }
  &.no-appearance {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.pos-relative {
  position: relative;
}
.pos-static {
  position: static;
}

.phones-default {
  display: flex;
  width: 100%;
  padding: 5px 0;
  background-color: $light;
  justify-content: space-between;
}

.title-default {
  font-size: 20px;
  @include media-breakpoint-up(lg) {
    font-size: 32px;
  }
}


// DEFAULT PAGE CONTENT
.page-content {
  &__header {
    padding: 12px 0;
    background-color: $red;

    @include media-breakpoint-up(lg) {
      padding: 37px 0;
    }

    &__title {
      color: $white;
    }
  }

  &__main {
    padding: 50px 0;
    @include media-breakpoint-up(lg) {
      padding: 40px 0 60px 0;
    }
    &__title {
      font-size: 18px;

      margin-bottom: 23px;
      @include media-breakpoint-up(lg) {
        font-size: 24px;
        margin-bottom: 40px;
      }
    }

    &__button {
      border-radius: 0;
      text-transform: uppercase;
      @include media-breakpoint-up(lg) {
        font-size: 20px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .content-texts {
    margin-bottom: 30px;
  }

  .why-company {
    p {
      margin-bottom: 0;
    }
    &__message {
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        max-width: 455px;
      }
    }
  }
}

.page-content {
  &-form {
    &__title {
      font-size: 18px;
      margin-bottom: 15px;
      @include media-breakpoint-up(lg) {
        font-size: 20px;
        margin-bottom: 30px;
      }
    }

    &__button {
      width: 170px;
      display: block;
      border-radius: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      text-transform: uppercase;
      
      text-align: left;
      padding: 15px 0px 15px 20px;
      font-size: 20px;
      @include media-breakpoint-down (md){
        padding: 10px 0px 10px 20px;
        font-size: 16px;
        width: 140px;
      }
    }

    &__input {
      min-height: 30px;
      border-radius: 0;
      color: #969696;
      font-size: 14px;
      
      @media (min-width: 320px) and (max-width: 375px){
        font-size: 11px;
        padding-left: 6px;
      }

      &::placeholder {
        color: #969696;
        
      }
    }
  }
}

.banner-sellcar {
  background: $black;
  padding: 30px 0 65px 0;
  @include media-breakpoint-up(lg) {
    height: 536px;
    padding-top: 50px;
    background: url(../img/compre-carro/banner.png) no-repeat center;
  }
}

.default-buttons {
  display: flex;
  justify-content: space-between;
  &__item {
    font-size: 14px;
    flex-basis: 45%;
    padding: 12px 0;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
      flex-basis: 215px;
    }

    @media(max-width: 320px) {
      font-size: 12px;
    }
  }
}

.bg-blind {
  @include media-breakpoint-up(lg) {
    background: url(../img/bg-blind.jpg) no-repeat center;
    background-position: center 10%;
    background-size: cover;
  }
}

.bg-sell {
  @include media-breakpoint-up(lg) {
    background: url(../img/compre-carro/bg-sell.jpg) no-repeat center;
    background-position: center 10%;
    background-size: cover;
  }
}

.funilaria-content.bg-blind {
  background-position: center 60%;
}

.container-full-mobile {
  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
}

.list-default {
  padding-left: 0;
  list-style: none;
}
textarea.page-content-form__input {
  min-height: 85px !important;
}
