.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.mt-35 {
  margin-top: 35px;
}

.fz-12 {
  font-size: 12px !important;
}

.mt-75 {
  margin-top: 75px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mr-20 {
  margin-right: 22px;
}

.p-25 {
  padding: 25px;
}

.pt-140 {
  padding-top: 140px;
}

.pb-160 {
  padding-bottom: 160px;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.py-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.fz-48 {
  font-size: 48px;
}

.fz-40 {
  font-size: 40px !important;
}

.fz-30 {
  font-size: 30px;
}

.fz-24 {
  font-size: 24px;
}

.fz-20 {
  font-size: 20px !important;
}

.fz-18 {
  font-size: 18px;
}

.fz-17 {
  font-size: 17px;
}

.fz-16 {
  font-size: 16px;
}

.fz-14 {
  font-size: 14px;
}

.fw-300 {
  font-weight: 300;
}

.fw-700 {
  font-weight: 700;
}

.lh-20 {
  line-height: 20px;
}

.lh-18 {
  line-height: 18px;
}

.lh-9 {
  line-height: 9px;
}

.r-120 {
  right: -120px !important;
}

.l-120 {
  left: -120px !important;
}

.min-h-240 {
  min-height: 240px;
}

.bottom--40 {
  bottom: -40px !important;
}

.slick-dots-cars {
  bottom: -60px !important;
  @media (min-width: 992px) {
    bottom: -80px;
  }
}

.mb-big-default {
  margin-bottom: 30px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 60px;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.text-white {
  color: #fff;
}

.text-blue {
  color: #00ade5;
  &:hover {
    color: #00ade5;
  }
}

.text-dark-blue {
  color: #003c65;
}

.text-gray {
  color: #666666;
}
.text-gray-medium {
  color: $gray;
}

.text-gray-two {
  color: #8994a0;
}

.text-gray-three {
  color: #6f6f6f;
}

.text-gray-four {
  color: #212529 !important;
  &:focus,
  &hover {
    color: #212529 !important;
  }
}

.color-red {
  color: $red;
}

.color-white {
  color: $white;
}

.text-green {
  color: #51ae30;
  &:hover {
    color: #51ae30;
  }
}

.open-flex {
  display: flex !important;
}

.text-bold-blue {
  font-weight: 700px;
  color: #003c65;
}

.shadow-level-1 {
  -webkit-box-shadow: 0px 0px 63px -4px rgba(125, 125, 125, 0.16);
  -moz-box-shadow: 0px 0px 63px -4px rgba(125, 125, 125, 0.16);
  box-shadow: 0px 0px 63px -4px rgba(125, 125, 125, 0.16);
}

.shadow-btn {
  box-shadow: 2px 2px 5px 1px rgba(142, 140, 140, 0.71);
}

.gradient-gray {
  background: -webkit-linear-gradient(left, rgba(51, 67, 76, 1) 27%, transparent);
  background: -o-linear-gradient(left, rgba(51, 67, 76, 1) 27%, transparent);
  background: linear-gradient(to right, rgba(51, 67, 76, 1) 27%, transparent);
}

.text-bold-black {
  font-weight: 700px;
  color: #000;
}

.border-gray {
  border: 2px solid #bdc3c7 !important;
}

.btn {
  cursor: pointer;
  &:focus {
    box-shadow: none;
  }
}

.bg-light-2 {
  background-color: #eaeaea;
}

.btn-orange {
  color: #fff;
  background-color: #ff871f;
  border-color: #ff871f;
  &:hover {
    color: #fff;
  }
}

.btn-green {
  color: #fff;
  background-color: $green;
  border-color: $green;
  &:hover {
    color: #fff;
    background-color: darken($green, 5%);
  }
}

.btn-red {
  color: #fff;
  background-color: $red;
  border-color: $red;
  transition: 0.2s all linear;
  &:hover {
    color: #fff;
    background-color: $gray;
    border-color: $gray;
  }
}

.btn-black {
  color: #fff !important;
  background-color: $black;
  border-color: $black;
  transition: 0.2s all linear;
  &:hover {
    color: #fff;
    background-color: $black;
    border-color: $black;
  }
}
.btn-black-medium {
  color: #fff;
  background-color: $black;
  border-color: $black;
  transition: 0.2s all linear;
  &:hover {
    color: #fff;
    background-color: $black;
    border-color: $black;
  }
}

.btn-blue {
  color: #fff;
  background-color: $blue;
  border-color: $blue;
  &:hover {
    color: #fff;
  }
}

.btn-blue-dark {
  color: #fff;
  background-color: blue;
  border-color: blue;
  &:hover {
    color: #fff;
  }
}

.btn-gray {
  color: #fff;
  background-color: #bac2c5;
  border: 4px solid #dfdfdf !important;
  border-radius: 13px !important;
  &:hover {
    color: #fff;
  }
}

.btn-yellow {
  color: $black;
  background-color: $yellow;
  border: 1px solid $yellow !important;
  border-radius: 0 !important;
  &:hover {
    color: $black;
    background: $yellow;
  }
  &:focus {
    color: $black;
    background: $yellow;
  }
}

.btn-black {
  color: $white;
  background-color: $black;
  border: 1px solid $black;
  border-radius: 0 !important;
  &:hover {
    color: $white;
    background: $gray;
  }
  &:focus {
    color: $white;
    background: $gray;
  }
}

.btn-black--yellow {
  color: $yellow;
  background-color: $black;
  border: 1px solid $black;
  border-radius: 0 !important;
  &:hover {
    color: $yellow;
    background: $gray;
  }
  &:focus {
    color: $yellow;
    background: $gray;
  }
}

.btn-white {
  background: $white;
  color: $blue;
  font-weight: 700;
  &:hover {
    color: $blue;
  }
  &--secundary {
    color: $white;
    background-color: transparent;
    border: 1px solid $white !important;
    &:hover {
      background: $white;
      color: $blue;
      transition: 0.2s linear;
      text-decoration: none;
    }
  }
}

.btn-default {
  height: 46px;
}

.no-border-radius {
  border-radius: 0;
}

.text-underline {
  text-decoration: underline;
}

.btn-default-color {
  border: 1px solid $white !important;
  line-height: 1.5em !important;
  color: $white;
  font-size: 20px !important;
  margin: 0 auto;

  width: 230px;
  &:hover {
    color: $white;
  }
}

.text-decoration-none {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

@media (min-width: 768px) {
  .border-right-md {
    border-right: 1px solid #c3c7c9;
  }
  .px-sm-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

@media (max-width: 991px) {
  .container-fluid-xs {
    width: 100% !important;
    max-width: 100% !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 768px) {
  .border-bottom-xs {
    border-bottom: 1px solid #c3c7c9;
  }
}
.title-pattern {
  font-size: 40px;

  color: $black;
  @include media-breakpoint-down(md) {
    font-size: 25px;
  }
}
.title-pattern-subtitle {
  font-size: 30px !important;

  color: #5e5e5e;
  @include media-breakpoint-down(md) {
    font-size: 18px !important;
    margin-bottom: 0;
  }
}
.background-white {
  background: $white;
}
.color--white {
  color: $white;
  font-size: 10px;
}
.color-white {
  color: $white !important;
}
.delete__vehicles__cars {
  display: flex;
  flex-direction: column;
  align-items: center;
}

html,
body {
  height: 100%;
}
a {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.collapsing {
  transition: 500ms !important;
}
.collapse {
  transition: 500ms;
}
.fade {
  transition: 500ms !important;
}
.modal-backdrop {
  background: $black;
  opacity: 0.6 !important;
}
textarea {
  outline: none;
}
.content-bg-pages {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  position: relative;
  &-default {
    background: url(../img/account/bg-acount.png) right no-repeat;
    height: 430px;
    width: 100%;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    pointer-events: none;
    // z-index: 1;
  }
  &-text {
    height: 100%;
    display: flex;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    padding-top: 90px;
  }
}
