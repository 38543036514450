.content-menu {
  position: fixed;
  top: 0;
  z-index: 1100;
  padding: 30px;
  left: -350px;
  width: 350px;
  max-width: 100%;
  height: 100vh;
  background-color: #f8f8f8;
  text-transform: uppercase;
  transition: left 0.35s linear;
  pointer-events: none;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  @media (max-width: 767px) {
    width: 95%;
    left: -95%;
  }
  &-active {
    left: 0;
    pointer-events: initial;
    .content-menu-itens-body-links {
			opacity: 1;
			-ms-transform: translateX(0);
			transform: translateX(0);
      &:nth-child(1) {
        transition-delay: 0.37143s;
      }
      &:nth-child(2) {
        transition-delay: 0.44286s;
      }
      &:nth-child(3) {
        transition-delay: 0.51429s;
      }
      &:nth-child(4) {
        transition-delay: 0.58571s;
      }
      &:nth-child(5) {
        transition-delay: 0.65714s;
      }
      &:nth-child(6) {
				transition-delay: 0.72857s;
      }
      &:nth-child(7) {
        transition-delay: 0.8s;
      }
      &:nth-child(8) {
				transition-delay: 0.87143s;
      }
      &:nth-child(9) {
				transition-delay: 0.94286s;
      }
      &:nth-child(10) {
        transition-delay: 1.01429s;
      }
      &:nth-child(11) {
        transition-delay: 1.08571s;
      }
  
		}
		.accordion-menu {
			opacity: 1 !important;
			-ms-transform: translateX(0) !important;
			transform: translateX(0) !important;
			transition-delay: 1.15714s !important;
		}
  }
  &-itens {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 14px;
        color: #939191;
        margin-bottom: 0px;
        font-family: "Font Light";
        padding-top: 10px;
      }
      &-close {
        font-size: 35px;
        color: $black;
        margin-bottom: 0px;
        opacity: 0.2;
        text-shadow: 0 1px 0 #fff;
        font-weight: normal;
        &:hover,
        &:focus {
          cursor: pointer;
        }
      }
    }
    &-body {
      padding-top: 30px;
      &-links {
        display: block;
        font-size: 12px;
        color: #939191 !important;
        font-family: "Font Regular";
        padding: 6px 0px;
        opacity: 0;
        -ms-transform: translateX(-20px);
        transform: translateX(-20px);
        transition: opacity 0.35s linear 0.35s, transform 0.35s linear 0.35s;
        &:hover,
        &:focus {
          cursor: pointer;
          color: #6d6b6b !important;
        }
      }
      .card {
        padding: 0;
        border: 0;
        background: 0;
        &-header {
          background: 0;
          padding: 0;
          border: 0;
        }
        .btn-link {
          border: 0;
          padding: 0;
          background: 0;
          display: block;
          font-size: 12px;
          color: #939191 !important;
          font-family: "Font Regular";
          padding: 6px 0px;
          transition: 500ms;
          text-decoration: none;
          width: 100%;
          text-align: left;
        }
        &-body {
          padding: 0px;
          &-link {
            display: block;
            font-size: 10px;
            color: #939191 !important;
            font-family: "Font Regular";
            padding: 6px 0px;
            transition: 500ms;
            &:hover,
            &:focus {
              cursor: pointer;
            }
          }
        }
			}
			.accordion-menu {
				opacity: 0;
        -ms-transform: translateX(-20px);
        transform: translateX(-20px);
        transition: opacity 0.35s linear 0.35s, transform 0.35s linear 0.35s;
			}
    }
    &-elements {
      &-login {
        display: flex;
        align-items: center;
        margin-top: 20px;
        &-item {
          font-size: 14px;
          color: #939191 !important;
          font-family: "Font Regular";
          display: flex;
          transition: 500ms;
          &:hover,
          &:focus {
            cursor: pointer;
            color: #6d6b6b !important;
          }
        }
        span {
          margin: 0 10px;
          font-size: 1.28571em;
          color: #939191 !important;
          font-family: "Font Regular";
          display: flex;
        }
      }
      &-list {
        font-family: "Font Regular";
        color: #939191 !important;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-top: 20px;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          color: #6d6b6b !important;
        }
        svg {
          margin-right: 5px;
          margin-bottom: 4px;
        }
      }
    }
  }
}
