// GLOBAL

$default-value: 1rem;
$default-value-small: .6875rem;
$small-12: .75rem;
$small-14: .875rem;
$font-18: 1.125rem;

// COLORS

$white: #fff;
$black: #000000;
$gray: #4c4c4c;
$gray-dark: #4d4d4d;
$gray-medium: #707070;
$red: #E62F2C;
$yellow: #FCD703;
$bg-linear: background-image(linear-gradient(to right,#F29020, #E93F39));

