.main-footer {
  font-size: 12px;
  font-family: "Font Light";
  color: #fff;
  @media (max-width: 767px){
    text-align: center;
  }
  p {
    font-size: 12px;
    font-family: "Font Light";
    color: #fff;
  }
  h4 {
    font-family: "Font Bold";
    text-transform: uppercase;
    font-size: 16px;
  }
  li {
    margin-bottom: 6px;
  }
  a {
    color: $white !important;
  }
  svg {
    fill: $white;
    max-width: 100%;
  }
}
.main-footer__gray-light-area {
  padding: 20px 0;
  background-color: #6b6a68;
}
.main-footer__gray-area {
  padding: 50px 0;
  background-color: #4d4d4f;
}
.main-footer h4 {
  margin-bottom: 20px;
}
.main-footer__contacts {
  white-space: nowrap;
}
.main-footer__links {
  text-transform: capitalize;
  column-count: 2;
}
.main-footer__social-media {
  margin-bottom: 92px;
  svg {
    height: 17px;
  }
  li {
    padding-right: 9px;
    padding-left: 9px;
  }
}
.main-footer__legal-links {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
}
.main-footer__social-media li {
  padding-right: 9px;
  padding-left: 9px;
}
.main-footer__contacts {
  white-space: nowrap;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
  & > li {
    display: inline-block;
  }
}
.btn:not(.header__btn):not(.dropdown-toggle):not(.btn-link) span {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
}
.content-button-footer {
  width: 100%;
  &-item {
    padding: 10px 0;
    text-align: center;
    border-color: #fff;
    color: #4d4d4f;
    text-transform: uppercase;
    font-size: 10px;
    background: $white;
    font-family: "Font Regular";
    border: 2px solid transparent;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 500ms;
    &:hover,
    &:focus {
      border-color: #939191;
    }
  }
}
@media (max-width: 991px){
  .main-footer__contacts, .main-footer__links {
      margin-bottom: 40px;
  }
}
@media (max-width: 767px){
  .main-footer__legal-links, .main-footer__logos, .main-footer__social-media, .main-footer__newsletter {
      margin: 30px 0 0 0;
  }
}
@media (max-width: 991px){
  .main-footer__social-media, .main-footer__newsletter {
      margin: 60px 0 0 0;
  }
}
